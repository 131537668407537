<template>
  <Component
    :is="'div'"
    class=""
    :class="[
      'ui-flex flex-col ui-items-start ui-bg-white ui-gap-sm',
      variantClasses[variant],
    ]"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { BaseCardProps } from "./types";

withDefaults(defineProps<BaseCardProps>(), {
  variant: "basket",
});

const variantClasses = {
  ["basket"]: "ui-border-b-sm ui-border-grey-light",
  ["search"]: "ui-border-t-sm ui-border-b-sm ui-border-grey-light",
  ["PDP"]: "ui-bg-red-default",
  ["product"]:
    "ui-shadow-xs ui-rounded-sm ui-ring-md hover:ui-ring hover:ui-ring-mkm-blue-light ui-transition-colors ui-delay-100",
};
</script>
